/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import Loader from "react-loader";

import { useEffect, Fragment, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import Colors, { addAlphaToColor } from "styles/colors";
import { ResponsiveSection } from "styles/container-elements";
import { Tabs } from "components/molecules/Tabs.molecule";
import { Search } from "components/templates/Search.template";
import SearchBarContainer from "../details/search/InventoryView.Details.SearchBarContainer";
import {
  useInventoryGraph,
  usePipelineGraph,
  useArrivalGraph,
  useDepartureGraph,
} from "pages/inventoryview/react-queries/InventoryViewHooks";

// TODO: remove temp data
import {
  // arrivalsData,
  // departuresData,
  // inventoryData,
  // pipelineData,
  pieChartData1,
  pieChartData2,
  pieChartData3,
  pieChartData4,
} from "../redux/data";

import {
  useInventoryChartBars,
  useArrivalsChartBars,
  usePipelineChartBars,
  useDeparturesChartAreas,
} from "./components/InventoryCharts.Columns";

import { ShippableNonShippableCountsWidget } from "./components/ShippableNonShippableCountsWidget";
import { QUERY_KEY_MAPPING } from "../details/search/InventoryView.Details.Search.Options";
import {
  defaultTab,
  selectedTab,
  tabsListStyle,
} from "../dashboard/components/InventoryNavigationTabs";
// TODO: Set correct order for imports
// refer: https://freightverify.atlassian.net/wiki/spaces/DE/pages/401178683/Frontend+Coding+Standards#Order-of-Imports

import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import {
  faChartLine,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { getColors } from "../utils/chart.utils";
import { PieChartWithLegends } from "components/molecules/PieChartWithLegends.molecule";
import { DeparturesCountsWidget } from "./components/DeparturesCountsWidget";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";
import { MediaQueries } from "components/responsive";

const InventoryViewInsights = ({
  location,
  locationId,
  fvId,
  solutionId,
  setCurrentLocation,
  redirectToDashboardView,
  redirectToDetailsView,
  setSearchFilter,
  isLoading,
  searchResults,
  toggleShowFilters,
  onsiteVinsResetSearch,
  fetchLocation,
}) => {
  // TODO: update namespace as inventory-insights
  const { t } = useTranslation("inventory-view");

  // set UTC date
  const todaysDate = moment().utc().format("YYYY-MM-DD");
  const arrivalDateInUTC = moment(todaysDate)
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const forecastedEndDateInUTC = moment(todaysDate)
    .add(7, "days")
    .format("YYYY-MM-DD");

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Details");

  // fetch current location details
  useEffect(() => {
    fetchLocation(fvId, locationId);
    setCurrentLocation(locationId);
  }, [setCurrentLocation, fvId, locationId, fetchLocation]);

  let params = {
    locationId: locationId,
    startDate: moment(todaysDate)
      .utc()
      .subtract(6, "days")
      .format("YYYY-MM-DD"), //today minus 6 days
    endDate: todaysDate, //today's date
  };
  const {
    transformedData: inventoryChartData,
    isFetching: isInventoryChartDataFetching,
    isError: isInventoryChartDataFetchingError,
  } = useInventoryGraph(params);

  let paramsForArrivalGraph = {
    locationId,
    arrivalDateInUTC,
    todaysDateInUTC: todaysDate,
    forecastedEndDateInUTC,
  };
  const {
    transformedData: arrivalGraphData,
    isFetching: isArrivalGraphDataFetching,
    isError: isArrivalGraphDataFetchingError,
  } = useArrivalGraph(paramsForArrivalGraph);

  const {
    transformedData: pipelineChartData,
    isFetching: isPipelineChartLoading,
    isError: isPipelineChartDataFetchingError,
  } = usePipelineGraph({ locationId });

  const {
    transformedData: departuresChartData,
    isFetching: isDeparturesChartDataFetching,
    isError: isDeparturesChartDataFetchingError,
  } = useDepartureGraph(params);

  // fetch columns for each chart
  const inventoryColumns = useInventoryChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.SHIPPABILITY,
  );
  const pipelineColumns = usePipelineChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.CURRENT_LOCATION,
  );
  const arrivalsColumns = useArrivalsChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.ARRIVAL_DATE,
  );
  const departureColumns = useDeparturesChartAreas();

  // Retrieve count from requested date
  const getDeparturesCount = useCallback(
    (departuresChartData, requestedDate) => {
      const yesterdaysActualDepartures = departuresChartData.data.filter(
        (data) => data.x === requestedDate,
      );

      return yesterdaysActualDepartures[0].y ?? 0;
    },
    [],
  );

  // Retrieve count from previous day
  const actualDeparturesYesterday = useMemo(() => {
    const yesterdaysDate = moment(todaysDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    if (departuresChartData.data.length !== 0) {
      return getDeparturesCount(departuresChartData, yesterdaysDate);
    }
  }, [getDeparturesCount, departuresChartData, todaysDate]);

  if (!location) {
    return null;
  }

  // total sum of shippable and nonShippable for inventory chart
  const totalSumInventory = inventoryChartData.data.reduce(
    (acc, item) => acc + item.shippable,
    0,
  );

  // total sum of shippable and nonShippable for pipeline chart
  const totalSumPipeline = pipelineChartData.reduce(
    (acc, item) => acc + item.shippable + item.nonShippable,
    0,
  );

  // today's inventory
  const todaysInventory = inventoryChartData.data
    .filter((item) => item.isTodaysDate)
    .reduce((acc, item) => acc + item.shippable, 0);

  // formatting for arrivals
  // TODO: set formatting from response and remove from here
  let todaysArrivalsCount = 0;

  if (isArrivalGraphDataFetchingError === false) {
    // today's arrival
    if (!_.isEmpty(arrivalGraphData) && !isArrivalGraphDataFetchingError) {
      const todaysArrivals = arrivalGraphData.filter((item) => {
        return item.isTodaysDate;
      });
      if (!_.isEmpty(todaysArrivals)) {
        todaysArrivalsCount = todaysArrivals[0].reported ?? 0;
      }
    }
  }

  // count of VINs at this location based on IN milestones >= 10 days ago
  // 5% of capacity
  let fivePercentOfTotalCapacity = 0;
  if (_.has(departuresChartData, "capacity") && departuresChartData.capacity) {
    fivePercentOfTotalCapacity = Math.trunc(
      (departuresChartData.capacity * 5) / 100,
    );
  }

  // set for home/details tab
  const tabIndex = 1;

  // set for piechart subtitle
  const subTitle = t("inventory-view:Shippable VINs");

  // common error block
  const errorForInsightsBlock = (
    <Text size={FontSize.size12} block>
      {t("inventory-view:Unable to fetch details. Please try again later.")}
    </Text>
  );

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em 2em 2em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Tabs selectedIndex={tabIndex}>
        <Tabs.TabList css={{ ...tabsListStyle }}>
          <Tabs.Tab
            css={{ ...defaultTab }}
            onClick={(evt) => redirectToDashboardView()}
          >
            <Icon src={faHouse} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Home")}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab css={{ ...selectedTab }}>
            <Icon src={faChartLine} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Details")}
            </Text>
          </Tabs.Tab>
          <div
            css={{
              marginLeft: "auto",
              minWidth: "50em",
              marginBottom: "10px",
            }}
          >
            <Search
              isLoading={isLoading}
              searchResults={searchResults}
              toggleShowFilters={toggleShowFilters}
              resetSearch={() => onsiteVinsResetSearch(solutionId)}
              showFilters={false}
              hideSavedSearch={true}
              SearchBarContainer={SearchBarContainer}
              hideSearchResults={true}
            />
          </div>
        </Tabs.TabList>
        <Tabs.TabPanel>
          <Text>Empty Tab</Text>
        </Tabs.TabPanel>
        <Tabs.TabPanel
          style={{ border: 0, background: "none", boxShadow: "none" }}
        >
          <Text
            size={FontSize.size32}
            block
            bold
            css={{
              paddingBottom: "0.5em",
              fontWeight: 500,
            }}
          >{`${location.name} (${location.code})`}</Text>
          <div
            css={{
              background: addAlphaToColor(
                Colors.inventoryView.INSIGHTS_BACKGROUND,
                10,
              ),
              padding: "20px 15px",
            }}
          >
            <Text size={FontSize.size12} block>
              {moment().format("MMM DD, YYYY")}
            </Text>
            <Icon
              src={faTriangleExclamation}
              color={Colors.inventoryView.BLUE}
              style={{ marginRight: 5 }}
            />
            <Text size={FontSize.size20} color={Colors.inventoryView.BLUE} bold>
              {t(`inventory-view:Today's Insights`)}
            </Text>
            <div
              css={{
                display: "grid",
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                [MediaQueries.mediumAndUp]: {
                  gridTemplateColumns: "repeat(4, 1fr)",
                },
                [MediaQueries.medium]: {
                  gridTemplateColumns: "repeat(4, 1fr)",
                },
                [MediaQueries.smallAndDown]: {
                  gridTemplateColumns: "repeat(1, 1fr)",
                },
                marginTop: "15px",
              }}
            >
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  {!isDeparturesChartDataFetchingError &&
                  departuresChartData.capacity ? (
                    <Text
                      color={Colors.text.DARK_GRAY}
                      size={FontSize.size36}
                      bold
                      block
                    >
                      <LocalizedNumber value={todaysInventory} />/
                      <LocalizedNumber value={departuresChartData.capacity} />
                    </Text>
                  ) : (
                    errorForInsightsBlock
                  )}
                  <Text
                    color={getColors(
                      "inventory",
                      todaysInventory,
                      departuresChartData.capacity,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Current Inventory")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Loader loaded={!isDeparturesChartDataFetching}>
                    <Text
                      color={Colors.text.DARK_GRAY}
                      size={FontSize.size36}
                      bold
                      block
                    >
                      <LocalizedNumber value={actualDeparturesYesterday} />
                    </Text>
                    <Text
                      color={getColors(
                        "daysOnSite",
                        actualDeparturesYesterday,
                        fivePercentOfTotalCapacity,
                      )}
                      size={FontSize.size18}
                      bold
                      block
                    >
                      {t("inventory-view:10+ Days on Site")}
                    </Text>
                  </Loader>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  {!isArrivalGraphDataFetchingError ? (
                    <Text
                      color={Colors.text.DARK_GRAY}
                      size={FontSize.size36}
                      bold
                      block
                    >
                      {todaysArrivalsCount}
                    </Text>
                  ) : (
                    errorForInsightsBlock
                  )}
                  <Text
                    color={getColors("forecastedArrivals", todaysArrivalsCount)}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Today's Forecasted Arrivals")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    {!isDeparturesChartDataFetchingError ? (
                      <Fragment>
                        <LocalizedNumber value={actualDeparturesYesterday} />
                        /
                        <LocalizedNumber
                          value={departuresChartData.primaryDepartureTarget}
                        />
                      </Fragment>
                    ) : (
                      errorForInsightsBlock
                    )}
                  </Text>
                  <Text
                    color={getColors(
                      "departures",
                      actualDeparturesYesterday,
                      departuresChartData.primaryDepartureTarget,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Yesterday's Departures")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
            </div>
          </div>
          <div
            css={{
              marginTop: "20px",
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              [MediaQueries.mediumAndUp]: {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              [MediaQueries.medium]: {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              [MediaQueries.smallAndDown]: {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
            }}
          >
            <PieChartWithLegends
              widgetData={pieChartData1}
              subTitle={subTitle}
              title={t("inventory-view:Time on Site")}
              x={"type"}
              y={"vins_associated"}
            />

            <PieChartWithLegends
              widgetData={pieChartData2}
              subTitle={subTitle}
              title={t("inventory-view:Initial ETA")}
              x={"type"}
              y={"vins_associated"}
            />

            <PieChartWithLegends
              widgetData={pieChartData3}
              subTitle={subTitle}
              title={t("inventory-view:Type")}
              x={"type"}
              y={"vins_associated"}
            />

            <PieChartWithLegends
              widgetData={pieChartData4}
              subTitle={subTitle}
              title={t("inventory-view:Assigned Carrier")}
              x={"type"}
              y={"vins_associated"}
            />
          </div>
          <div
            css={{
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              [MediaQueries.mediumAndUp]: {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
              [MediaQueries.medium]: {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
              [MediaQueries.smallAndDown]: {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
              marginTop: "2em",
            }}
          >
            <ShippableNonShippableCountsWidget
              widgetData={inventoryChartData.data}
              isLoading={
                isInventoryChartDataFetching && isDeparturesChartDataFetching
              }
              isLoadingError={
                isInventoryChartDataFetchingError ||
                isDeparturesChartDataFetchingError
              }
              title={t("inventory-view:Inventory")}
              bars={inventoryColumns}
              xAxisTickFormat={{
                difference: 300,
                maxLimit: 1200,
              }}
              showTotals={true}
              totalSum={totalSumInventory}
              threshold={departuresChartData.capacity}
            />

            <ShippableNonShippableCountsWidget
              widgetData={pipelineChartData}
              isLoading={isPipelineChartLoading}
              isLoadingError={isPipelineChartDataFetchingError}
              title={t("inventory-view:Pipeline")}
              bars={pipelineColumns}
              xAxisTickFormat={{
                difference: 2500,
                maxLimit: 10000,
              }}
              showTotals={true}
              totalSum={totalSumPipeline}
            />

            <ShippableNonShippableCountsWidget
              widgetData={arrivalGraphData}
              isLoading={isArrivalGraphDataFetching}
              isLoadingError={isArrivalGraphDataFetchingError}
              title={t("inventory-view:Arrivals")}
              bars={arrivalsColumns}
              xAxisTickFormat={{
                difference: 50,
                maxLimit: 200,
              }}
              showTotals={false}
              showAlert={true}
            />

            <DeparturesCountsWidget
              widgetData={departuresChartData}
              showCapactiy={false}
              title={t("inventory-view:Departures")}
              bars={departureColumns}
              yAxisTickFormat={{
                difference: 30,
                maxLimit: 120,
              }}
              isLoading={isDeparturesChartDataFetching}
            />
          </div>
        </Tabs.TabPanel>
      </Tabs>
    </ResponsiveSection>
  );
};

InventoryViewInsights.propTypes = {
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  solutionId: PropTypes.string,
  redirectToDashboardView: PropTypes.func,
  redirectToDetailsView: PropTypes.func,
  setSearchFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  searchResults: PropTypes.array,
  toggleShowFilters: PropTypes.func,
  onsiteVinsResetSearch: PropTypes.func,
  fetchLocation: PropTypes.func.isRequired,
  setCurrentLocation: PropTypes.func,
  fvId: PropTypes.string,
};

export default InventoryViewInsights;
